/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a7a51be4-b710-4f43-aac9-7a580ce23df0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vOaJy16l0",
    "aws_user_pools_web_client_id": "3t921366rg8pgr5e3s7rad11s5",
    "oauth": {
        "domain": "codex-alpha.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://codex.bellese.io/app/,http://localhost:8000/app/,https://beta.codex.bellese.io/app/,https://alpha.codex.bellese.io/app/",
        "redirectSignOut": "https://codex.bellese.io/,https://beta.codex.bellese.io/,https://alpha.codex.bellese.io/,http://localhost:8000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "io-bellese-codex-20200131161302-hostingbucket-alpha",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3cp4lts2cjeg7.cloudfront.net"
};


export default awsmobile;
